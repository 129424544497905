<template>

  <site-header/>

  <section class="home">

    <div class="container-fluid">
      <div class="main-slider">

          <div class="row">
              <div class="mobile-slider"></div>
          </div>

          <div class="container">
              <div class="row">
                  <div class="col-lg-6 col-md-12">

                      <h1 class="heading-1">Capture moments, Create memories.</h1>
                      <div class="sub-heading-section">
                          <span class="span-1">Stunning Photography Solutions for Every Occasion</span>
                      </div>

                      <div class="row">
                          <div class="col-md-12">

                              <router-link to="/events" class="btn-red-filled">
                                  Book an Upcoming Session
                              </router-link>

                              <span v-if="$store.state.selectedCity" class="location-label body-text">
                      <img class="icon" src="../assets/icons/location-icon-red.svg">
                      Your are in <span class="special">{{ $store.state.selectedCity.attributes.name }}</span>
                  </span>

                          </div>
                      </div>

                  </div>
                  <div class="col-md-6">
                      <img class="slider-image" alt="logo" src="../assets/images/banner-image.png">
                  </div>
              </div>
          </div>

      </div>
    </div>
    <!-- /main slider -->

    <!-- <div class="container-fluid as-seen-on-widget">
          <div class="container">
              <div class="row">
                  <div class="col-md-3"><label>As seen on</label></div>
                  <div class="col-md-9">
                      <ul>
                          <li><img class="" alt="logo" src="../assets/images/logos/the-new-york-times.png"></li>
                          <li><img class="" alt="logo" src="../assets/images/logos/the-new-york-times.png"></li>
                          <li><img class="" alt="logo" src="../assets/images/logos/the-new-york-times.png"></li>
                      </ul>
                  </div>
              </div>
          </div>
    </div> -->
    <!-- /as-seen-on widget -->

    <div class="container-fluid categories-widget">
          <div class="container">
            <h2 class="heading-1 text-center">For all your occasions</h2>
            <p class="body-text-large text-center">Be it a birthday party, graduation, or family photo shoot, we can help capture all of life's precious moments.</p>
            <CategoriesCarousel/>
          </div>
    </div>
    <!-- /For all your occasions -->

    <div class="container-fluid how-it-works-widget">
        <div class="container">
          <h2 class="heading-1 text-center">How it works</h2>
          <p class="body-text-large text-center">Professional photography has never been more simpler and accessible.</p>

          <div class="row">
            <div class="column col-lg-3 col-md-6">

              <div class="step-block">
                <img src="../assets/images/steps/book-session-thumbnail.jpg">
                <div class="step">1</div>
                <h3>Book a <span>FREE</span> session</h3>
                <p>Select from a range of upcoming events with our professional photographers.</p>
              </div>

            </div>

            <div class="column col-lg-3 col-md-6">

              <div class="step-block">
                <img src="../assets/images/steps/do-the-shoot-image.jpg">
                <div class="step">2</div>
                <h3>Do the shoot</h3>
                <p>Enjoy your session with a professional photographer and get the best possible moments captured.</p>
              </div>

            </div>

            <div class="column col-lg-3 col-md-6">

              <div class="step-block">
                <img src="../assets/images/steps/select-your-photos-image.jpg">
                <div class="step">3</div>
                <h3>Select your photos</h3>
                <p>Our photographers will provide a set of touched up images from your shoot for your choosing.</p>
              </div>

            </div>

            <div class="column col-lg-3 col-md-6">

              <div class="step-block">
                <img src="../assets/images/steps/get-edited-photos-image.jpg">
                <div class="step">4</div>
                <h3>Pay only for what you select</h3>
                <ul>
                  <li><span>$25</span> per edited photo</li>
                  <li><span>$150</span> for 10 edited photos</li>
                  <li><span>$250</span> for 25 edited photos</li>
                </ul>
              </div>

            </div>
          </div>
        </div>
    </div>
    <!-- /How it works widget -->

    <div class="container-fluid value-widget">
        <div class="container">
          <h2 class="heading-1 text-center">The value we bring</h2>
          <div class="values-carousel-wrapper">
            <values-carousel/>
          </div>
        </div>
    </div>
    <!-- /The value widget -->

    <div class="container-fluid upcoming-sessions-widget">
          <div class="container">
            <h2 class="heading-1 text-center">Book your own session on an upcoming shoot</h2>
            <div class="upcoming-sessions-wrapper">
              <upcoming-sessions/>
            </div>
          </div>
    </div>
    <!-- /Upcoming sessions widget -->

    <div class="container-fluid testimonials-widget">
          <div class="container">
            <h2 class="heading-1 text-center">Customer testimonials</h2>
            <p class="body-text-large text-center">Don’t take our word for it, see what our clients have to say.</p>

            <div class="testimonials-carousel-wrapper">
                <div class="desktop-version">
                    <testimonials-carousel/>
                </div>

                <div class="mobile-version">
                    <testimonials-carousel-mobile></testimonials-carousel-mobile>
                </div>

            </div>
          </div>
    </div>
    <!-- /Testimonials widget -->

    <div class="container-fluid photographer-signup-widget">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <img class="banner-image" src="../assets/images/photographer-signup.jpg" alt="">
              </div>
              <div class="col-md-6">
                <div class="block-wrapper">
                  <h2 class="heading-2">Become a <span>Photoppz</span> photographer</h2>
                  <p>Gain a host of benefits by becoming a Photoppz photographer. </p>

                  <router-link to="/photographer-register" class="btn-green-filled">
                    Learn more
                  </router-link>
                </div>
              </div>
            </div>
          </div>
    </div>
    <!-- /For all your occasions -->

  </section>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import SiteHeader from '@/components/SiteHeaderHome'
import CategoriesCarousel from '@/components/CategoriesCarousel';
import ValuesCarousel from '@/components/ValuesCarousel';
import TestimonialsCarousel from '@/components/TestimonialsCarousel';
import TestimonialsCarouselMobile from '@/components/TestimonialsCarouselMobile.vue';
import UpcomingSessions from '@/components/UpcomingSessions';

export default {
  name: 'HomeView',
  components: {
    SiteHeader,
    CategoriesCarousel,
    ValuesCarousel,
    TestimonialsCarousel,
    TestimonialsCarouselMobile,
    UpcomingSessions
    // HelloWorld
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

//// ipad & bellow // ++++++++++++++++++++++++++++++++++++++++++++ //
@media (max-width:768px) {

  .mobile-slider {
    background-image: url("../assets/images/banner-image.png");
    //background-image: url(http://localhost:8080/img/banner-image.cce8a0ce.png);
  }

}

</style>
